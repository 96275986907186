/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.take-photos-container {

    width: 17%;
    margin-right: 5%;
    margin-left: 27%;
    display: inline-block;
    justify-content: right;
    cursor: pointer;

}

.uploadEobContainer {
    width: 17%;
    margin-left: 5%;
    margin-right:27%;
    display: inline-block;
    justify-content: center;
    cursor: pointer;
}

.upload-btn {

    width: 100%;

}

.takePhotosButton {

    width: 100%;

}

.claimImageContainer {

    height: 60vh;
    width: 100%;
    overflow-y: scroll;
    display: relative;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* display: inline-block; */
}

.claimImg {

    position: relative;
    width: 100%;
    /* display: inline-block; */
    margin-right: 5px;
}

.claimImageContainer img {

    /* display: inline-block; */
    width: 100%;

}

.claimImgCancel {
    position: absolute;
    top: 0px;
    left: 0px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    border-radius: 30px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}

/*EOB Popup Styles*/

.popupEOB {
    position: absolute;
    z-index: 4; 
    top: 20%; 
    background-color: rgb(244,244,244);
    box-shadow: 0 3px 6px rgb(214, 214, 214);
    width: 95%;
    left: 2.5%;
    height: auto;
    border-radius: 10px;
    padding-bottom: 3vh;
}

.popupEOB img {
    float: right;
    margin: 20px;
    width: 20px;
}

.popupEOB h2 {
    color: var(--color-primary);
    margin: 50px 20px;
}

.popupEOB p {
    padding: 0 20px;
}

.popupEOB h3 {
    margin-left: 20px;
}

.popupEOB ul {
    padding: 20px;
    position: relative;
    bottom: 30px;
}


.popupEOB li {
    list-style: none;
    padding: 5px 0;

  
}

.cameraWrapper {
    position: relative;
    width: 100% !important;
}

video {
    width: 100% !important;
}

.react-html5-camera-photo img {
    width: 100% !important;
}

.uploadEob {
    padding: 10px 10px;
    width: 20%;
    cursor: pointer;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
