/*
// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory
*/

* {
	font-family: 'Montserrat', sans-serif;
	--color-primary: rgb(39,66,124);
	--color-secondary: rgb(145,102,51);
	--color-primary-accent: rgb(164,194,219);
	--color-secondary-accent: rgb(209,157,91);
	--color-light: rgb(246,245,243);
	--color-bright: rgb(81,154,210);
	--color-text: rgb(53,53,53);
	--color-text-light: rgb(200,200,200);
	--color-text-medium: rgb(140,140,140);
	--color-button: rgb(0,117,175);
	--color-red: rgb(210,0,0);
	--color-green: rgb(0,150,0);

	--max-app-width: 450px;
	--max-titlebar-height: 80px;
    --titlebar-padding: 10px;
	--side-instructions-height: 100px;
	--side-instructions-margin: 15px;
}

.navBackdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0);
    z-index: 1;
}

nav {
	position: absolute;
	top: 0;
	bottom: 0;
	/* left: 0; */
	right: 100%;
	width: 80%;
	/* opacity: 0; */
	background-color: var(--color-text);
	transition: all 0.1s ease-in-out;
	z-index: 999;

}

.nav-opened {
	right: 20%;
	width: 80%;
}

nav img {
	opacity: 1 !important;
	position: absolute;
	right: -70px;
	/* left: 10px; */
	
	top: 20px;
	width: 40px;
	
	height: auto;
	cursor: pointer;
	/* background-color: rebeccapurple; */
}

.logo-container {
    position: absolute;
	width: 100%;
	height: var(--max-titlebar-height);
    background-color: #fff;
    top: 0vh;
    left: 0vh;
    padding: var(--titlebar-padding) 0;
    z-index: 900;
}

.logo-small {
	position: absolute;
	width: auto;	
	height: calc(var(--max-titlebar-height));
	left: calc(50% - var(--max-titlebar-height)/2);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-content: center;
}

.logo-big {
	position: absolute;
	width: auto;
	height: calc(var(--max-titlebar-height));
	left: calc(50% - var(--max-titlebar-height)/2);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-content: center;	
}

.nouser-container {
	padding: 30vh 20px 5vh 20px;
}

.container {
	padding: 20vh 20px 5vh 20px;

}

.addToHomescreen {
    text-align: center;
    font-size: 11pt;
    color: var(--color-text-medium);
    color: var(--color-bright);
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    box-shadow: 0 0 5px #999;
    /* border-top: solid 1px #000 */
}

.addToPhone {
    text-align: center;
    font-size: 11pt;
    color: var(--color-text-medium);
    color: var(--color-bright);
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    box-shadow: 0 0 5px #999;
    /* border-top: solid 1px #000 */
}

h1 {
	font-size: 18pt;
	color: var(--color-primary);
	font-weight: 600;
	line-height: 1.5em;
}

h2 {
	font-size: 14pt;
	color: var(--color-text-medium);
	font-weight: 300;
	margin-top: -0.5em;
	line-height: 1.5em;
	margin-bottom: 2.5em;
}

h3 {
	font-size: 13pt;
	color: var(--color-text);
	font-weight: 500;
	margin-top: 2.5em;
	line-height: 1.5em;
	margin-bottom: 1.5em;
}

p {
	font-size: 12pt;
	color: var(--color-text);
	font-weight: 400;
	margin-top: 2em;
	margin-bottom: 0;
	line-height: 1.8em;
	
}

label {
	display: block;
	color: var(--color-text);
	font-size: 11pt;
	line-height: 2em;
	margin-top: 20pt;
}

input[type=text], input[type=password] {
	width: 100%;
	border: none;
	border-bottom: 2px solid rgb(230,230,230);
	padding: 0;
	height: 28pt;
	line-height: 28pt;
	font-size: 14pt;
	color: var(--color-text);
	margin: 0;
}

input[type=text]:focus, input[type=password]:focus {
	box-shadow: none;
	outline: none;
	border-bottom: 2px solid var(--color-secondary-accent);
}

button {
	background-color: var(--color-button);
	border: none;
	outline: none;
	color: white;
	height: 32pt;
	line-height: 32pt;
	padding: 0;
	margin-top: 20pt;
	font-size: 13pt;
	text-transform: uppercase;
	font-family: 'Raleway', sans-serif;
	border-radius: 16pt;
	box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.15);
    cursor: pointer;
}

button:hover, button:focus {
	background-color: var(--color-secondary-accent);
}

a {
	color: var(--color-bright);
	text-decoration: none;
	font-weight: 600;
    cursor: pointer;
}

.menu {
	padding-top: 40pt;
	z-index: 10 !important;
}

.menu-item {
	display: block;
	height: 40pt;
	line-height: 40pt;
	border-top: 2px solid rgb(30,30,30);
	border-bottom: 2px solid rgb(30,30,30);
	margin-bottom: -2px;
	padding-left: 45pt;
	color: white !important;
	font-weight: 400 !important;
	font-size: 12pt;
	background-image: url('/item.png');
	background-repeat: no-repeat;
	background-size: 8pt;
	background-position: 24pt 50%;
}


.menu-item:hover, .menu-item:focus {
	background-color: var(--color-secondary);
}

.phone {
	font-size: 22pt;
	line-height: 32pt;
	width: 180pt;
	margin-top: 40pt;
	margin-left: calc(50% - 100pt);
	text-align: left;
	padding-left: 30pt;
	background-image: url('/phone.svg');
	background-repeat: no-repeat;
	background-size: 20pt;
	background-position: 2px;
}

.notice {
	position: absolute;
	/* top: 25vw; */
	top: calc(var(--max-titlebar-height) + var(--titlebar-padding)*2);
	height: 34pt;
	line-height: 39pt;
	left: 0;
	right: 0;
	/* background-color: green; */
	background-color: var(--color-light);
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15);
	padding: 0 40px;
	font-size: 11pt;
}

.notice-count {
	float: left;
	color: white;
	background-color: var(--color-secondary-accent);
	height: 15pt;
	line-height: 16pt;
	width: 15pt;
	border-radius: 50%;
	text-align: center;
	margin-top: 12pt;
	margin-right: 7pt;
	font-size: 8pt;
}

.notice-link {
	float: right;
	font-weight: 500;
	color: var(--color-bright);
	padding-right: 13pt;
	background-image: url('/next.png');
	background-position: right;
	background-size: 9pt;
	background-repeat: no-repeat;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
}

.notice-link:hover {
	padding-right: 18pt;
}

.hint {
	display: inline-block;
	font-weight: 500;
	color: var(--color-bright);
	text-transform: uppercase;
	line-height: 1.5em;
	margin-top: 1.5em;
	margin-bottom: 2em;
	padding-right: 20pt;
	background-image: url('/next.png');
	background-position: 100% 3pt;
	background-size: 12pt;
	background-repeat: no-repeat;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
}

.hint:hover {
	padding-right: 26pt;
}

.error {
	text-align: center;
	color: var(--color-red);
	font-size: 11pt;
	margin-top: 2.5em;
	padding-top: 22pt;
	background-image: url('/error.png');
	background-size: 16pt;
	background-position: top;
	background-repeat: no-repeat;
}

.success {
	text-align: center;
	color: var(--color-green);
	font-size: 11pt;
	/* margin-top: 2.5em; */
	/* padding-top: 22pt; */
	/* background-image: url('/error.png'); */
	/* background-size: 16pt;
	background-position: top;
	background-repeat: no-repeat; */
}



.next-page {
	background-color: rgb(244,244,244);
	color: rgb(0,117,178);
    cursor: pointer;
}

.next-page:hover {
	color: white;
}

/*Styles for FinishSignature Page*/

.claim-btns label {
	font-size: 16px;
}


.claim-btns input[type= text] {
	font-size: 16px;
	width: 50%;
	margin-left: 10px;
}

/*Styles for Claim Submitted page*/

.contact-number {
	display: flex;
	
}

.contact-number img {
	width: 20px;
	height: 30px;
	position: relative;
	top: 35px;
	margin: 0 auto;
}

.contact-number h3 {
	position: relative;
	right: 90px;
	bottom: 10px;
}

/*Styles for Sign up page */
.signup-container {
	padding: 30vh 20px 5vh 20px;
}
.signup-container  h1 {
	text-align: center;
}


/*center page Styles*/

.center-container {
	padding: calc(24vw + 80pt) 25px 5vh 25px;
    text-align: center;
    position: relative;
}

h1.your-plan {
	text-align: center;
	margin-bottom: 50px;
}

.plan {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

/*Account Settings Styles*/

.account-settings-container label {
	 clear: right;
	
}



/*forgot Password page Styles*/

.forogt-pword-container {
	margin-top: 80px;
}

.forogt-pword-container h1 {
	text-align: center;
}

.forogt-pword-container p {
	text-align: center;
	margin-bottom:80px;
}

.forogt-pword-container img {
	position: absolute;
	top: 8vh;
	width: auto;
	height: 20vh;
	left: calc(50vw - 10vh);
	z-index: 1;
}

.change-plan-btn {
	color: var(--color-secondary-accent);
	font-size: 16px;
    margin-top: 15px; 
    cursor: pointer;
}

.newClaim_removePlanList {
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid black;
    /* cursor: pointer; */
}

.newClaim_removePlanItem {
    padding: 15px 20px;
    text-align: center;
}

.newClaim_removePlanBtn {
    position: relative;
    right: 15px;
    top: 5px;
}

.appContainerPC { 
	position: fixed;
	top: 0;
	bottom: 0;
	width: var(--max-app-width);
	left: calc(50% - var(--max-app-width)/2) !important;
	
	background-color: white !important;
	overflow-y: auto;
	overflow-x: hidden;
}

.appContainer_leftPC {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: calc(50% - var(--max-app-width)/2) !important;
	/* max-width: var(--max-app-width); */
	background-color: red !important;
	/* background-color: var(--color-primary) !important; */
	z-index: 1000;
}

.appComponent {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--color-primary);
	/* border: 3px chartreuse solid; */
}

.appContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow-y: auto;
	/* max-width: var(--max-app-width); */
	background-color: white !important;
}

.appContainer_left {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 0;
	z-index: 1000;
}

.templateComponent {
	/* position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: var(--max-app-width) !important; */
	/* width: 100px; */
}

.appContainer_addApp {
    position: absolute;
    display: block;
    bottom: var(--side-instructions-height);
    width: calc(50% - var(--max-app-width)/2 - var(--side-instructions-margin)*2);
    /* right: calc(50% + var(--max-app-width)/2 + 20px); */
    left: var(--side-instructions-margin);
    height: auto;
    background-color: white;
	z-index: 2001;
	cursor: pointer;
}

.languageToggleContainer {
    --toggle-width: 100px;
	--toggle-height: 30px;
    --toggle-top: calc(var(--max-titlebar-height)/2 - var(--toggle-height)/2);
    /* --toggle-top: calc(var(--max-titlebar-height)/2 - var(var(--toggle-height)/2)); */
    --toggle-thumb-margin: 1px;
	--toggle-thumb-diameter: calc(var(--toggle-height) - var(--toggle-thumb-margin)*2);
	--toggle-thumb-checked-position: calc(var(--toggle-width) - var(--toggle-thumb-diameter) - var(--toggle-thumb-margin));
	
    position: absolute;
    z-index: 901;
    top: var(--toggle-top);
    right: 1vh;
}

.languageToggleContainer .react-toggle-track {
    /* top: var(--toggle-top); */
    width: var(--toggle-width);
    height: var(--toggle-height);
}

.languageToggleContainer .react-toggle-thumb {
    width: var(--toggle-thumb-diameter);
    height: var(--toggle-thumb-diameter);
}

.react-toggle--checked .react-toggle-thumb {
  left: var(--toggle-thumb-checked-position);
}

/* .languageToggleContainer .react-toggle-track {
    width: 100px;
    background-color: red;
}

.languageToggleContainer .react-toggle-track:hover {
    background-color: red;
}

.languageToggleContainer .react-toggle--checked .react-toggle-track {
    background-color: red;
}

.languageToggleContainer .react-toggle--checked .react-toggle-track:hover {
    background-color: darkred;
}

.languageToggleContainer .react-toggle--checked .react-toggle-thumb:hover .react-toggle-track {
    background-color: red;
} */

.lblEnglish {
    position: relative;
    top: 5px;
    /* left: 4px; */
    color: white;
}

.lblSpanish {
    position: relative;
    top: 5px;
    right: 48px;
    color: white;
}