/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

/*Claim Checkbox Styles*/
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--color-text-light);
    border-radius: 4px;
  }


  .checkbox-container input:checked ~ .checkmark {
    background-color: var(--color-secondary-accent);
  }


  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
/*Signature Canvas Area Styles*/
.signatureWrapper {
    /* border: 1px solid black; */
    width: auto;
    height: auto;
    width: 100%;
    position: relative;
}


.signatureCanvas {
    /*border: 1px solid rgba(0,0,0, 0.5);*/
    box-shadow: 0 3px 6px rgb(217,217,217);
    border-radius: 4px;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.signature {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
}

.cleardiv {
    margin-top: 15px;
    color: rgb(140,140,140);
    padding: 0 20px;
    font-size: 20px;
    font-weight: 600;
}

.clearButton {
    margin-right: 5px;
    border-radius: 4px;
    color: var(--color-secondary-accent);
}

.saveButton {
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 20px;
    font-size: 20px;
    font-weight: 600;
}

.claimForContainer {
    position: relative;
}

.claimForContainer input {
    position: relative;
	width: 100%;
	border: none;
	border-bottom: 2px solid rgb(230,230,230);
	padding: 0;
	height: 28pt;
	line-height: 28pt;
	font-size: 14pt;
	color: var(--color-text);
	margin: 0;
}

.claimForContainer input:active {
    border: none;
}

.claimForRemoveBtn {
    position: absolute;
    /* float: right; */
    /* top: -20px; */
    right: 10px;
    margin-top: 10px;
    /* top: 2px; */
}