.remove-plan-btn {
	color: var(--color-secondary-accent);
	font-size: 14px;
	margin-top: 35px; 
}

.remove-plan-btn img {
	margin-left: 10px;
	width: 12px;

}

.change-password-btn {
	color: var(--color-secondary-accent);
	font-size: 14px;
	margin-top: 15px;
	float: left; 
	cursor: pointer;
}

.change-password-btn img {
	margin-left: 10px;
	width: 6px;
}


.acct_editBtn {
    float: right;
    margin-top: 15px;;
}

.account-settings-label {
    clear: right;
    margin-top: 20px;
}

/* .remove-plan-btn {
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	left: 0;
} */


.change-password-confirm button {
    background-color: var(--color-button);
	border: none;
	outline: none;
	color: white;
	height: 32pt;
	line-height: 32pt;
	padding: 0;
	margin-top: 20pt;
	font-size: 13pt;
	text-transform: uppercase;
	font-family: 'Raleway', sans-serif;
	border-radius: 16pt;
	box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.15);
}



.invalid-text {
	margin-top: 5px;
	color: rgb(206,51,51);
	background-color: rgb(249,203,203);
	border-radius: 4px;
	padding: 5px 10px;

}

.valid-text {
	margin-top: 5px;
	color: #cec;
	background-color: rgb(62,177,46);
	border-radius: 4px;
	padding: 5px 10px;
}