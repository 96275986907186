



.claim-start-date {
    /* display: inline-block; */
    float: right;
	font-size: 13px;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 0px;
    width: 30%;
}

.claim-status {
    position: absolute;
    left: calc(50% - 40px);
    margin-top: 10px;
    /* left: -10px; */
    /* top: 10px; */
    display: inline-block;
	font-weight: 500;
    color: white;
    padding: 0 8px;
    line-height: 20px;
    width: auto;
    border-radius: 5px;
    font-size: 9pt;
    text-transform: uppercase;
}

.claim-container {
    position: relative;
    /* border-bottom: solid rgb(39,66,124); */
    border-bottom: 1px solid rgb(235,235,235);
    padding: 8px 0;
}

.claimNumber {
	display: inline-block;
    width: 40%;
    margin-bottom: 0px;
    margin-top: 10px;
    color: var(--color-text);
    font-weight: 500 !important;
	
}

.claim-detail-container {
    padding: 6px;
    padding-left: 20px;
    position: relative;
    font-size: 10pt;
}

.claim-detail-container span:nth-child(3) {
  position: absolute;
  right: 0;
  font-weight: 500;
  color: var(--color-text);
  padding-right: 5px;
}